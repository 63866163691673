import React from "react";
import AwesomeSlider from "react-awesome-slider";
import withAutoplay from 'react-awesome-slider/dist/autoplay';
import "react-awesome-slider/dist/styles.css";
import * as style from "./ImageSlider.module.scss";

const AutoplaySlider = withAutoplay(AwesomeSlider);

export const ImageSlider = ({ animation, images, height }) => {
  return (
    <AutoplaySlider
      animation={animation}
      className={style.image_slider}
      height="250px"
      play={true}
      interval={3000}
    >
      {images.map((img, index) => (
        <div
          key={index}
          data-src={img.image.publicURL}
          alt={`image of ${img.image.name}`}
        >
          <section className="slider_data">
            <p className="title">{img.data.heading}</p>
            <p className="subtitle">{img.data.content}</p>
          </section>
        </div>
      ))}
    </AutoplaySlider>
  );
};
