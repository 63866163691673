import * as React from "react";
import { Link } from "gatsby";
import { useStaticQuery, graphql } from "gatsby";
import { MainLayout } from "../layouts/MainLayout";
import { ImageSlider } from "../components/ImageSlider";
import gear from "../static/icons/gear.svg";
import mining from "../static/icons/mining.svg";
import factory from "../static/icons/factory.svg";
import plan from "../static/icons/plan.svg";
import suspension from "../static/icons/suspension_2.png";
import drivetrain from "../static/icons/drivetrain.png";

// markup
const IndexPage = () => {
  let carousel = [];
  const slider_query = useStaticQuery(graphql`
    query {
      slidersJson {
        sliders {
          content
          heading
          id
        }
      }
      allFile(filter: { sourceInstanceName: { eq: "backgrounds" } }) {
        nodes {
          name
          publicURL
        }
      }
    }
  `);

  const backgrounds = slider_query.allFile.nodes; // images fetched from static/slider_images
  const sliders = slider_query.slidersJson.sliders; // json data fetched from data/Sliders

  for (let _slide of sliders) {
    let image = backgrounds.find(
      (img) => parseInt(_slide.id) === parseInt(img.name)
    );
    let slide = {
      image,
      data: _slide,
    };
    carousel.push(slide);
  }

  console.log(carousel);

  return (
    <MainLayout>
      {/* Carousel */}
      <ImageSlider animation="cubeAnimation" images={carousel} height="450px" />

      {/* Services */}
      <section className="area-of-work section">
        <div className="container">
          <h3 className="subtitle text-medium">What we offer</h3>
          <h2 className="title">Business Verticals & Services</h2>
          <ul className="columns">
            {/* Industrial Equipments */}
            <li className="column">
              <Link activeClassName="active" to="/industrial-equipment">
                <div className="service-icon">
                  <figure className="image is-96x96">
                    <img src={mining} alt="Mining" />
                  </figure>
                </div>
                <div className="service-heading">
                  <p className="title is-5">Certified Parts</p>
                  <p className="subtitle is-6">Industrial & Mining Equipment</p>
                </div>
              </Link>
            </li>
            {/* Driveline Parts */}
            <li className="column">
              <Link activeClassName="active" to="/driveline-parts">
                <div className="service-icon">
                  <figure className="image is-96x96">
                    <img src={gear} alt="Mining" />
                  </figure>
                </div>
                <div className="service-heading">
                  <p className="title is-5">Driveline Parts</p>
                  <p className="subtitle is-6">
                    Trucks, Mining & Armoured Vehicles
                  </p>
                </div>
              </Link>
            </li>
            {/* Axles Systems */}
            <li className="column">
              <Link activeClassName="active" to="/suspension">
                <div className="service-icon">
                  <figure className="image is-96x96">
                    <img src={drivetrain} alt="suspension" />
                  </figure>
                </div>
                <div className="service-heading">
                  <p className="title is-5">Axles Systems</p>
                  <p className="subtitle is-6">
                    Design,Development & Servicing
                  </p>
                </div>
              </Link>
            </li>
            {/* Suspensions */}
            <li className="column">
              <Link activeClassName="active" to="/suspension">
                <div className="service-icon">
                  <figure className="image is-96x96">
                    <img src={suspension} alt="suspension" />
                  </figure>
                </div>
                <div className="service-heading">
                  <p className="title is-5">Suspension Systems</p>
                  <p className="subtitle is-6">
                    Heavy-Duty Suspension Parts & Systems
                  </p>
                </div>
              </Link>
            </li>
            {/* Security Systems */}
            <li className="column">
              <Link activeClassName="active" to="/security-systems">
                <div className="service-icon">
                  <figure className="image is-96x96">
                    <img src={factory} alt="Factory" />
                  </figure>
                </div>
                <div className="service-heading">
                  <p className="title is-5">Security Systems</p>
                  <p className="subtitle is-6">Measurement & Control</p>
                </div>
              </Link>
            </li>
            {/* Design Consultancy */}
            <li className="column">
              <Link
                activeClassName="active"
                to="/design-technology-consultancy"
              >
                <div className="service-icon">
                  <figure className="image is-96x96">
                    <img src={plan} alt="Jeep" />
                  </figure>
                </div>
                <div className="service-heading">
                  <p className="title is-5">Design & Technology Consultancy</p>
                  <p className="subtitle is-6">Drawings & Production</p>
                </div>
              </Link>
            </li>
          </ul>
        </div>
      </section>

      {/* About */}
      <section className="about-us section">
        <div className="container">
          <div className="content">
            <section className="about-us-title">
              <div>
                <p className="subtitle text-medium">Company</p>
                <p className="title">AVT SARL</p>
              </div>
            </section>
            <section className="about-us-content">
              <p className="about-content">
                AVT Sarl is a leading consulting partner for mining industry,
                specialising in design, consultancy and supply of driveline
                technologies. We are a leading supplier of driveline parts,
                security systems and certified spare-parts to mining units,
                industrial plants and military vehicles.
              </p>
              <p className="contact_button">
                <button className="button">
                  <Link to="/contact">Contact Us</Link>
                </button>
              </p>
            </section>
          </div>
        </div>
      </section>

      <section className="clients"></section>
    </MainLayout>
  );
};

export default IndexPage;
